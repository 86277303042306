
.fullscreen {
    &.empty-div {
        @media #{$md-layout} {
            display: none;
        }

        @media #{$lg-layout} {
            display: none;
        }
    }
}



/*--------------------
    Code New  
----------------------*/
.pv-slider-area .slide .inner h1.title {
    font-size: 80px;
    line-height: 100px;
}

.pv-slider-area .slide.slide-style-1 .inner {
    padding-top: 50px;
}

.pv-slider-area .slide.slide-style-1::before,
footer.pv-callto-action.call-to-action-wrapper::before {
    display: block;
}


@media #{$lg-layout} {
    .pv-slider-area .slide.slide-style-1 .inner {
        padding-top: 70px;
    }
}

@media #{$md-layout} {
    .pv-slider-area .slide .inner h1.title {
        font-size: 60px;
        line-height: 75px;
    }

    .pv-slider-area .slide.slide-style-1 .inner {
        padding-top: 70px;
    }
}

@media #{$sm-layout} {
    .pv-slider-area .slide .inner h1.title {
        font-size: 60px;
        line-height: 75px;
    }

    .pv-slider-area .slide.slide-style-1 .inner {
        padding-top: 65px;
    }
}

@media #{$large-mobile} {
    .pv-slider-area .slide .inner h1.title {
        font-size: 37px;
        line-height: 48px;
    }
}

.w-100 {
    width: 100%;
    height: 100%;
}