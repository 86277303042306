/*====================
    About Area 
======================*/

.about-area {
    // background: #960000;
    background-repeat: no-repeat;
    background-size: cover;

    .rn-progress-bar {
        .single-progress {
            margin-top: 26px;
        }
    }
}

.des-area {
    background-image: url(/assets/images/bg/bg-image-9.webp);
    background-repeat: no-repeat;
    background-size: cover;

    .section-title {
        h2 {
            &.title {
                color: var(--color-white);
            }
        }

        p {
            &.description {
                font-size: 20px;
                color: var(--color-white) !important;
            }
        }
    }
}

.bottom-area {
    background-image: url(/assets/images/bg/bg-image-10.webp);
    background-repeat: no-repeat;
    background-size: cover;

    .section-title {
        h2 {
            &.title {
                color: var(--color-primary);
            }
        }

        p {
            &.description {
                font-size: 20px;
                color: var(--color-black) !important;
            }
        }
    }
}

.rn-finding-us-area {
    .container {
        .row {
            justify-content: center;
        }
    }

    .inner {
        position: relative;

        @media #{$lg-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$md-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        @media #{$sm-layout} {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            display: flex;
            flex-direction: column-reverse;
        }

        .content-wrapper {
            .content {
                padding: 80px 0px;
                position: relative;
                z-index: 2;
                width: 800px;
                border-radius: 10px;
                text-align: center;

                @media #{$lg-layout} {
                    padding: 60px 50px;
                    width: 100%;
                    border-radius: 0;
                }

                @media #{$md-layout} {
                    padding: 60px 50px;
                    width: 100%;
                    border-radius: 0;
                }

                @media #{$sm-layout} {
                    padding: 30px 30px;
                    width: 100%;
                    border-radius: 0;
                }

                h4 {
                    color: #ffffff;
                    font-size: 36px;
                    font-weight: 500;
                    margin-bottom: 20px;

                    @media #{$sm-layout} {
                        font-size: 26px;
                        margin-bottom: 12px;
                    }
                }

                p {
                    font-size: 18px;
                    color: #ffffff;
                    font-weight: 300;
                    margin-bottom: 41px;

                    @media #{$sm-layout} {
                        margin-bottom: 24px;
                        padding-right: 0;
                    }
                }
            }
        }

        .thumbnail {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;

            @media #{$lg-layout} {
                position: static;
                height: 100%;
            }

            @media #{$md-layout} {
                position: static;
                height: 100%;
            }

            @media #{$sm-layout} {
                position: static;
                height: 100%;
            }

            img {
                border-radius: 10px;

                @media #{$lg-layout} {
                    border-radius: 0;
                }

                @media #{$md-layout} {
                    border-radius: 0;
                }

                @media #{$sm-layout} {
                    border-radius: 0;
                }
            }
        }
    }
}

.about-inner {
    @media #{$md-layout} {
        padding-top: 40px;
    }

    @media #{$sm-layout} {
        padding-top: 40px;
    }

    .icon {
        display: inline-block;
        width: 60px;
        height: 60px;
        background: var(--color-primary);
        border-radius: 100%;
        text-align: center;
        color: #fff;
        line-height: 58px;
        font-size: 27px;
        margin-bottom: 17px;
        padding-right: 3px;


        svg {
            stroke-width: 1;
        }
    }

    .section-title {
        h2 {
            &.title {
                line-height: 1.3;
                margin-bottom: 35px;
            }
        }

        p {
            &.description {
                font-weight: 300;
                color: var(--color-gray);

                a {
                    color: rgba(29, 29, 36, 1);

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        }

    }
}


.about-wrapper {
    .thumbnail {
        position: relative;
        z-index: 2;

        img {
            border-radius: 8px;
            box-shadow: none;

        }
    }

    .section-title {

        h2 {
            font-size: 40px;
        }

        p {
            margin-bottom: 0px;
        }
    }
}