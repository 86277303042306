
/*==========================
    Contact Address 
============================*/

input,
textarea {
    @extend %transition;
}

.blog-comment-form .inner .rnform-group input:focus ,
.blog-comment-form .inner .rnform-group textarea:focus {
    border-color: var(--color-primary) ;
}

