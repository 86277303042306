/*====================
    gold-api-area
======================*/

.gold-api-area {
    .gold-price-container {
        color: white;
        background-color: var(--color-primary);
        padding: 10px;
        width: auto;
        height: 400px;
        margin: auto;
    }

    .section-title {
        color: #ffffff;

        h4 {
            text-align: left;
            color: #ffffff;
            align-content: center;
            margin-left: 10px;
            margin-top: 10px;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            text-align: right;
            color: #ffffff;
            line-height: 0 !important;
            margin-top: 25px;
        }

        .logo{
            width: 80px;
        }

        @media #{$lg-layout} {
            .description{
                margin-left: -15px;
            }
            .logo-title{
                place-content: center;
                margin-bottom: 10px;
            }
            p {
                text-align: right;
                margin-top: 50px;
                margin-bottom: -20px;
            }
        }

        @media #{$md-layout} {
            .logo-title{
                margin-bottom: 10px;
            }
            p {
                margin-bottom: 10px;
            }
        }

        @media #{$sm-layout} {
            .logo-title{
                place-content: center;
                margin-bottom: 10px;
            }
            h4 {
                text-align: center;
                color: #ffffff;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                margin-bottom: 15px;
            }

            .logo{
                text-align: center;
            }
        }

    }

    

    .gold-price-table {
        width: 100%;
        margin-top: 20px;
        border-bottom: 0;

        .list{
            margin-top: 20px;
            margin-bottom: 20px;
        }
        tr {
            background-color: #ffffff17;
        }
        th{
            font-size: 16px;
        }
        td{
            font-size: 16px;
        }
        .text-color-type{
            text-align: center;
        }
        .text-color-buy{
            color: red;
            text-align: center;
        }
        .text-color-sell{
            color: #00A800;
            text-align: center;
        }
        .text-color-diff{
            color: #4ac9ff;
            text-align: center;
        }
        
        .text-price{
            font-size: 36px;
            text-align: center;
            background-color: rgba(255, 255, 255, .0901960784);
        }

        @media #{$lg-layout} {
            th{
                font-size: 12px;
            }
            td{
                font-size: 12px;
            }
            .text-price{
                font-size: 26px;
            }
        }
    
        @media #{$md-layout} {
            th{
                font-size: 16px;
            }
            td{
                font-size: 16px;
            }
        }
    
        @media #{$sm-layout} {
            th{
                font-size: 12px;
            }
            td{
                font-size: 12px;
            }
            .text-price{
                font-size: 20px;
            }
        }
    }

    .gold-price-table th,
    .gold-price-table td {
        border-top: 0;
        padding: 5px;
        text-align: left;
    }

    .gold-price-table th {
        background-color: #111111;
    }

    .change {
        color: red;
    }

    time {
        font-style: italic;
    }

    .tradingview-widget-container {
        width: auto !important;
        height: 400px !important;
    }

    
}