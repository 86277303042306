/*====================
    About Area 
======================*/

.shortcut-menu-area {
    .block {
        padding: 10px;
    }

    .im_shortcut-menu {
        position: relative;
        z-index: 2;
        overflow: hidden;
        border-radius: 10px;
        transition: transform .65s cubic-bezier(.05, .2, .1, 1), box-shadow .65s cubic-bezier(.05, .2, .1, 1);

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 85%;
            display: block;
            z-index: 1;
            content: '';
            background: linear-gradient(to bottom, rgba(15, 15, 15, 0), rgba(15, 15, 15, .75) 100%);
            transition: opacity .65s cubic-bezier(.05, .2, .1, 1);
            cursor: pointer;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 85%;
            display: block;
            z-index: 1;
            content: '';
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--color-primary) 100%);
            transition: opacity .65s cubic-bezier(.05, .2, .1, 1);
            cursor: pointer;
            opacity: 0;
        }

        .thumbnail_inner {
            .thumbnail {
                a {
                    display: block;

                    img {
                        transition: transform 1s ease, opacity 0.5s ease 0.25s;
                        border-radius: 10px;
                        width: 575px;
                        height: auto;
                        max-height: 575px;
                    }
                }
            }
        }

        .content {
            .inner {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 30px 10px;
                z-index: 2;
                width: 100%;

                @media #{$sm-layout} {
                    padding: 15px 10px;
                }

                .portfolio_heading {
                    transition: opacity .65s cubic-bezier(.05, .2, .1, 1), transform .65s cubic-bezier(.05, .2, .1, 1);

                    .title {
                        color: #ffffff;
                        font-size: 24px;
                        text-align: center;
                        margin-bottom: 80px;

                        a {
                            color: #ffffff;
                        }

                        @media #{$lg-layout} {
                            font-size: 20px;
                            margin-bottom: 50px;
                        }

                        @media #{$md-layout} {
                            font-size: 18px;
                            margin-bottom: 30px;
                        }

                        @media #{$sm-layout} {
                            font-size: 26px;
                            margin-bottom: 80px;
                        }
                    }
                }

                .portfolio_hover {
                    opacity: 0;
                    position: absolute;
                    bottom: 35px;
                    margin-top: 10px;
                    transform: translateY(20px);
                    line-height: 1.5em;
                    max-width: 80%;
                    transition: opacity 0.18s cubic-bezier(0.05, 0.2, 0.1, 1), transform 0.18s cubic-bezier(0.05, 0.2, 0.1, 1);

                    @media #{$sm-layout} {
                        bottom: 15px;
                    }

                    p {
                        color: #ffffff;
                        opacity: 0.7;
                    }
                }

            }
        }

        &:hover {
            transform: translateY(-10px);
            box-shadow: 0 25px 55px rgba(253, 71, 102, 0.22);

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }

            .thumbnail_inner {
                .thumbnail {
                    a {
                        img {
                            transform: scale(1.1);
                            transition: all 9s cubic-bezier(.1, .2, 7, 1);
                        }
                    }
                }
            }

            .content {
                .inner {
                    .portfolio_heading {
                        transform: translateY(-5px);
                        transition: opacity .65s cubic-bezier(.05, .2, .1, 1), transform .65s cubic-bezier(.05, .2, .1, 1);

                    }

                    .portfolio_hover {
                        transform: translateY(0);
                        opacity: 1;
                        transition: opacity .65s cubic-bezier(.05, .2, .1, 1) 0.15s, transform .65s cubic-bezier(.05, .2, .1, 1) 0.15s;
                    }

                }
            }
        }

    }
}