/* Social icon Styles  */

ul {
    &.social-share-herder {
        padding: 0;
        margin: 0;
        margin: -6px;

        li.facebook,
        li.instagram,
        li.tiktok,
        li.line {
            margin: 6px;

            a {
                width: 40px;
                display: inline-block;
                line-height: 36px;
                height: 40px;
                color: rgb(255, 255, 255);
                border-radius: 100%;
                text-align: center;
                font-size: 20px;
                @extend %transition;
            }

            &:hover {
                a {
                    background: var(--color-primary);
                    border-color: var(--color-primary);
                    color: #ffffff;
                    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
                    transform: translateY(-3px);
                }
            }
        }

        li.facebook {
            a {
                background-color: #0866ff;
            }
        }

        li.instagram {
            a {
                background: rgb(255, 0, 179)
            }
        }

        li.tiktok {
            a {
                background-color: #000;
            }
        }

        li.line {
            a {
                background-color: #00ab00;
            }
        }

        &.color-theme {
            li {
                a {
                    border-color: var(--color-primary);
                    color: var(--color-primary);

                    &:hover {
                        background: var(--color-primary);
                        border-color: var(--color-primary);
                        color: #ffffff;
                    }
                }

            }
        }

        &.rn-lg-size {
            margin-top: 0 !important;

            li {
                a {
                    width: 50px;
                    height: 50px;
                    line-height: 44px;
                    font-size: 16px;
                }
            }
        }

        &.social-style--2 {
            li {
                a {
                    border: 0 none;
                    width: 30px;
                    height: auto;
                    text-align: left;
                    line-height: inherit;
                    font-size: 16px;
                }

                &:hover {
                    a {
                        background: transparent;
                        box-shadow: none;

                    }
                }
            }

            &.color-theme {
                li {
                    a {
                        color: var(--color-primary);

                        &:hover {
                            color: currentColor;
                        }
                    }

                }
            }

            &.color-black {
                li {
                    a {
                        color: $body-color;
                        opacity: 0.8;

                        &:hover {
                            color: var(--color-primary);
                            opacity: 1;
                        }
                    }

                }
            }
        }

    }

    &.social-share-footer {
        padding: 0;
        margin: 0;
        margin: -6px;

        li {
            margin: 6px;

            a {
                width: 40px;
                display: inline-block;
                height: 40px;
                border: 2px solid rgba(156, 156, 156, 0.75);
                line-height: 35px;
                color: rgb(255, 255, 255);
                border-radius: 100%;
                text-align: center;
                font-size: 14px;
                @extend %transition;
            }

            &:hover {
                a {
                    background: var(--color-primary);
                    border-color: var(--color-primary);
                    color: #bfeb12;
                    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
                    transform: translateY(-3px);
                }
            }
        }

        &.color-theme {
            li {
                a {
                    border-color: var(--color-primary);
                    color: var(--color-primary);

                    &:hover {
                        background: var(--color-primary);
                        border-color: var(--color-primary);
                        color: var(--color-secondary);
                    }
                }

            }
        }

        &.rn-lg-size {
            margin-top: 0 !important;

            li {
                a {
                    width: 50px;
                    height: 50px;
                    line-height: 44px;
                    font-size: 16px;
                }
            }
        }

        &.social-style--2 {
            li {
                a {
                    border: 0 none;
                    width: 30px;
                    height: auto;
                    text-align: left;
                    line-height: inherit;
                    font-size: 16px;
                }

                &:hover {
                    a {
                        background: transparent;
                        box-shadow: none;

                    }
                }
            }

            &.color-theme {
                li {
                    a {
                        color: var(--color-primary);

                        &:hover {
                            color: currentColor;
                        }
                    }

                }
            }

            &.color-black {
                li {
                    a {
                        color: $body-color;
                        opacity: 0.8;

                        &:hover {
                            color: var(--color-primary);
                            opacity: 1;
                        }
                    }

                }
            }
        }

    }
}