/*--------------------
Footer Styles 
----------------------*/


.footer-wrapper {
    position: relative;
}

.footer-left {
    background: linear-gradient(145deg, var(--color-primary) 0%, var(--color-secondary) 100%);
    padding: 120px 120px;
    position: relative;
    z-index: 2;
    margin-right: -15px;
    border-top-right-radius: 6px;

    @media #{$lg-layout} {
        padding: 120px 80px;
    }

    @media #{$md-layout} {
        padding: 60px 70px;
    }

    @media #{$sm-layout} {
        padding: 60px 30px;
    }

    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-image: url("/assets/images/pattern-1.webp");
        content: "";
        z-index: 1;
        opacity: 0.5;
        border-top-right-radius: 6px;
    }

    .inner {
        z-index: 5;
        position: relative;

        &::after {
            //content: "tryDO";
            content: url(/assets/images/logo/big-logo.webp);
            position: absolute;
            right: 0;
            top: 50%;
            z-index: -1;
            font-size: 240px;
            font-weight: 900;
            color: rgba(0, 0, 0, 0.1);
            transform: translateY(-50%);
            width: 295px;
            height: 301px;
        }

        >span {
            color: #fff;
            font-size: 15px;
            text-transform: uppercase;
            letter-spacing: 4px;
            display: block;
            margin-bottom: 9px;

            @media #{$large-mobile} {
                letter-spacing: 1px;
                margin-bottom: 9px;
            }
        }

        h2 {
            color: #ffffff;
            font-size: 75px;
            font-weight: 900;
            font-family: $body-font;
            line-height: 90px;

            @media #{$lg-layout} {
                font-size: 50px;
                line-height: 61px;
            }

            @media #{$sm-layout} {
                font-size: 45px;
                line-height: 51px;
            }
        }

        a {
            &.rn-button-style--2 {
                margin-top: 30px;
                border-color: #ffffff;
                color: #ffffff;

                @media #{$sm-layout} {
                    margin-top: 10px;
                    padding: 10px 23px;
                }

                @media #{$large-mobile} {
                    padding: 7px 16px;
                }

                &:hover {
                    background: #fff;
                    border-color: #fff;
                    color: var(--color-primary);
                }
            }
        }
    }
}

.footer-style-01 {
    .footer-link {
        h1 {
            font-weight: 500;
            margin-bottom: 30px;
            color: #cacaca;
            font-size: 20px;
            @media #{$sm-layout} {
                margin-bottom: 14px;
            }
        }
        ul {
            &.ft-link {
                padding: 0;
                margin: 0;
                list-style: none;
                li {
                    color: var(--color-white);
                    font-size: 16px;
                    a {
                        @extend %transition;
                        @extend .im-hover;
                        color: #ffffff;
                        font-weight: 400;
                        font-size: 16px;
                        padding: 5px 0;
                        &:hover {
                            color: var(--color-secondary);
                        }
                    }

                    & + li {
                        margin-top: 13px;

                        @media #{$sm-layout} {
                            margin-top: 8px;
                        }
                    }
                }
            }
        }

        .icon {
            margin-right: 10px;
            font-weight: 400;
            font-size: 16px;
        }
    }

    .ft-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .logo {
            margin-bottom: 30px;
        }
        p {
            color: var(--color-white);
            font-size: 14px;
            line-height: 30px;
            letter-spacing: 1px;
            a {
                color: var(--color-gray);
                @extend .im-hover;
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
}



.footer-area {
    .logo {
        a {
            display: block;
            img {
                max-height: 120px;
            }
        }
    }
}
