/*====================
    Contact Area 
======================*/

.contact-area {

    .contact-top {
        background-repeat: no-repeat;
        background-size: cover;

        .section-title {
            h3 {
                font-size: 30px;
                font-weight: 400;
            }


        }
    }

    .address {
        h4 {
            font-size: 24px;
            font-weight: 600;
            color: var(--color-primary);
        }
        p{
            color: var(--color-black);
            margin-bottom: 15px;
            b{
                font-weight: 500;
            }
        }
        img{
            margin-bottom: 50px;
        }
        .icon{
            margin-right: 10px;
        }


    }

    .button {
        background-color: #f4f5f5;
        /* Green */
        border: none;
        width: 100%;
        color: var(--color-primary);
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        font-weight: 00;
        cursor: pointer;
    }

    .button:hover {
        background-color: #dadada;
    }
}