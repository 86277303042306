/*====================
    About Area 
======================*/
.gold-saver-area {
    .section-1 {
        // background: #960000;
        background-image: url(/assets/images/bg/bg-image-11.webp);
        background-repeat: no-repeat;
        background-size: cover;

        .section-title {
            .row {
                justify-content: center;
            }

            h4 {
                &.description {
                    font-size: 26px;
                    color: var(--color-white) !important;
                }
            }

            h3 {
                &.title {
                    color: var(--color-white);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 80px;
                }

                &.title-color {
                    color: var(--color-secondary);
                    font-size: 46px;
                    margin-right: 10px;

                }
            }

            p {
                &.description {
                    line-height: 15px;
                    font-size: 20px;
                    color: var(--color-white) !important;
                }

                &.description-color {
                    line-height: 15px;
                    color: var(--color-secondary);
                    font-size: 26px;
                    font-weight: 600;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }

        .button {
            background-color: #f4f5f5;
            /* Green */
            border: none;
            width: 100%;
            color: var(--color-primary);
            padding: 10px 32px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 14px;
            font-weight: 00;
            cursor: pointer;
        }

        .button:hover {
            background-color: #dadada;
        }
    }

    .section-2 {
        .section-title {
            .row {
                justify-content: center;
            }

            h4 {
                line-height: 10px;
            }

            h3 {
                &.title {
                    color: var(--color-white);
                    font-size: 20px;
                    font-weight: 400;
                }

                &.title-color {
                    color: var(--color-secondary);
                    font-size: 46px;

                    @media #{$sm-layout} {
                        font-size: 30px;
                    }
    
                    @media #{$large-mobile} {
                    }

                }
            }

            p {
                &.description {
                    font-size: 20px;
                    color: rgb(44, 44, 44);
                }

                &.description-color {
                    color: rgb(4, 253, 0);
                    font-size: 26px;
                    font-weight: 600;
                }
            }
        }
    }

    .section-3 {
        background-image: url(/assets/images/bg/bg-image-12.webp);
        background-repeat: no-repeat;
        background-size: cover;

        .section-title {
            .row {
                justify-content: center;
            }

            h4 {
                line-height: 10px;
            }

            p {
                &.description {
                    font-size: 20px;
                    color: rgb(44, 44, 44);
                }

                &.description-color {
                    color: rgb(4, 253, 0);
                    font-size: 26px;
                    font-weight: 600;
                }
            }
        }
    }

    .section-4 {
        .section-title {
            .row {
                justify-content: center;
            }

            h4 {
                &.description {
                    font-size: 26px;
                    color: var(--color-white) !important;
                }
            }

            h3 {
                &.title {
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 34px;
                }

                &.title-color {
                    color: var(--color-secondary);
                    font-size: 32px;
                    margin-right: 10px;
                    margin-left: 10px;

                }
            }

            p {
                &.description {
                    line-height: 15px;
                    font-size: 20px;
                    color: rgb(0, 0, 0);
                }

                &.description-color {
                    line-height: 15px;
                    color: var(--color-secondary);
                    font-size: 26px;
                    font-weight: 600;
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }  
    }
}